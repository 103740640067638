import { template as template_aa73971b2c3d409d87d8ebebb7aef480 } from "@ember/template-compiler";
const SidebarSectionMessage = template_aa73971b2c3d409d87d8ebebb7aef480(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
