import { template as template_ce9e82ff7edb4273821ecc0a291a71f4 } from "@ember/template-compiler";
const WelcomeHeader = template_ce9e82ff7edb4273821ecc0a291a71f4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
