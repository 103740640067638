import { template as template_32530ab67fb5442e9e8c51aa4f8983c5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_32530ab67fb5442e9e8c51aa4f8983c5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
