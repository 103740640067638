import { template as template_807229be751c4e60aef4ce3f021d59c7 } from "@ember/template-compiler";
const FKLabel = template_807229be751c4e60aef4ce3f021d59c7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
