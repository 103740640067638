import { template as template_a6031807ff9942d88726f3e1a03053b3 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKCol from "discourse/form-kit/components/fk/col";
const FKRow = template_a6031807ff9942d88726f3e1a03053b3(`
  <div class="form-kit__row" ...attributes>
    {{yield (hash Col=FKCol)}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKRow;
